import React from 'react';
import logo from './hitchvibe_logo.png';
export default function App() {
  // Inline style equivalents for Tailwind CSS classes used in the original code
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
    header: {
      padding: '1rem', // Tailwind px-4
      display: 'flex',
      alignItems: 'center',
      height: '3.5rem', // Tailwind h-14
    },
    headerLargePadding: {
      paddingLeft: '1.5rem', // Tailwind lg:px-6
      paddingRight: '1.5rem',
    },
    nav: {
      marginLeft: 'auto',
      display: 'flex',
      gap: '1rem', // Tailwind gap-4, sm:gap-6
    },
    main: {
      flex: '1',
    },
    section: {
      width: '100%',
      padding: '3rem 0', // Tailwind py-12, md:py-24, lg:py-32
      borderBottom: '1px solid #e5e7eb', // Border color like Tailwind border-b
    },
    grid: {
      maxWidth: '700px', // Tailwind max-w-7xl
      // display: 'grid',
      // gap: '1.5rem', // Tailwind gap-6, lg:gap-12
      // gridTemplateColumns: '1', // Tailwind lg:grid-cols-[1fr_2fr]
    },
    textSection: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: '1rem', // Tailwind space-y-4
    },
    title: {
      fontSize: '1.875rem', // Tailwind text-3xl
      fontWeight: 'bold',
      letterSpacing: '-0.05em', // Tailwind tracking-tighter
    },
    subTitle: {
      // maxWidth: '600px',
      width: '100%',
      color: '#6b7280', // Tailwind text-gray-500
      fontSize: '1.25rem', // Tailwind text-xl
    },
    imageContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    imageStyle: {
      maxWidth: '100%',
      height: 'auto',
      borderRadius: '0.75rem', // Tailwind rounded-xl
    },
    footer: {
      display: 'flex',
      flexDirection: 'column', // Tailwind flex-col
      gap: '0.5rem', // Tailwind gap-2
      padding: '1.5rem', // Tailwind py-6, px-4, md:px-6
      borderTop: '1px solid #e5e7eb', // Tailwind border-t
      alignItems: 'center', // Center content in footer
    },
    footerNav: {
      display: 'flex',
      gap: '1rem', // Tailwind gap-4, sm:gap-6
      marginLeft: 'auto', // For sm:flex-row alignment
    },
    linkStyle: {
      textDecoration: 'none',
      color: '#374151', // Tailwind text-gray-700
      fontSize: '0.875rem', // Tailwind text-sm
      fontWeight: 'medium',
      paddingBottom: '4px', // Tailwind underline-offset-4
    },
    buttonStyle: {
      display: 'inline-flex',
      height: '2.5rem', // Tailwind h-10
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '0.375rem', // Tailwind rounded-md
      padding: '0 2rem', // Tailwind px-8
      fontSize: '0.875rem', // Tailwind text-sm
      fontWeight: 'medium',
      transition: 'colors 0.15s ease-in-out',
      // no underlines
      textDecoration: 'none',
    },
    primaryButton: {
      backgroundColor: '#1f2937', // Tailwind bg-gray-900
      color: '#f9fafb', // Tailwind text-gray-50
      ':hover': {
        backgroundColor: '#374151', // Tailwind hover:bg-gray-700
      },
      // bold
      fontWeight: 'bold',
    },
    secondaryButton: {
      backgroundColor: '#ffffff', // Tailwind bg-white
      border: '1px solid #d1d5db', // Tailwind border-gray-200
      color: '#1f2937', // Tailwind text-gray-900
      ':hover': {
        backgroundColor: '#f3f4f6', // Tailwind hover:bg-gray-100
      },
    },
  };

  // Function components for icons can include additional styling as needed
  function CheckCircleIcon() {
    // SVG component similar to the example provided
    return (
      <svg
        style={{ height: '24px', width: '24px' }} // Example size, adjust as needed
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        {/* SVG paths */}
      </svg>
    );
  }

  function MountainIcon() {
    // SVG component similar to the example provided
    return (
      <svg
        style={{ height: '24px', width: '24px' }} // Example size, adjust as needed
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        {/* SVG paths */}
      </svg>
    );
  }

  // Note: The conversion of className to inline styles is done manually. This example provides a basic conversion. For complex Tailwind classes like responsive (e.g., md:px-6), pseudo-classes (e.g., hover:), or others (e.g., dark:), you'll need to adjust the styles dynamically based on the environment (e.g., media queries for responsiveness) or state (e.g., hover states in JavaScript).

  return (
    <>
    {/* Header section with navigation */}
<header style={{...styles.header, ...styles.headerLargePadding}}>
  <a href="#" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center',
    textDecoration: 'none',
}}>
    {/* <h3 style={{
      fontSize: '1.5rem', // Tailwind text-xl
      fontWeight: 'bold',
      color: '#1f2937', // Tailwind text-gray-900
      textDecoration: 'none',
    }}>
      HitchVibe
      </h3> */}

      <img src={logo} alt="HitchVibe" style={{height: 'auto', width: '10rem'}} />

  </a>
  {/* <nav style={styles.nav}>
    <a href="#" style={styles.linkStyle}>Features</a>
    <a href="#" style={styles.linkStyle}>Pricing</a>
    <a href="#" style={styles.linkStyle}>About</a>
    <a href="#" style={styles.linkStyle}>Contact</a>
  </nav> */}
</header>

<main style={styles.main}>
  <section style={styles.section}>
    <div style={{
      // padding
      padding: '1.5rem', // Tailwind px-4, md:px-6
    }}>
      <div style={styles.grid}>
        <div style={styles.textSection}>
          <h1 style={styles.title}>Escape the Post-Concert Chaos 🚌</h1>
          <p style={styles.subTitle}>
            HitchVibe is the easiest way home after concerts in Singapore. 
            
            With HitchVibe, you're not just booking a ride; you're choosing the smart way to close out an unforgettable night. Let's keep the good vibes rolling ✌🏼
          </p>
          {/* Buttons */}
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <a href="https://t.me/hitchvibebot" style={{...styles.buttonStyle, ...styles.primaryButton}}>Open HitchVibe in Telegram</a>
            {/* <a href="#" style={{...styles.buttonStyle, ...styles.secondaryButton}}>Contact Sales</a> */}
          </div>
        </div>
        {/* <div style={styles.imageContainer}>
          <img
            alt="Placeholder"
            src="/placeholder.svg"
            style={styles.imageStyle}
          />
        </div> */}
      </div>
    </div>
  </section>
  {/* Repeat the section structure for other parts of the main content as needed */}
</main>

{/* Footer */}
<footer style={styles.footer}>
  <p style={{ fontSize: '0.875rem', color: '#6b7280' }}>© 2024 HitchVibe. All rights reserved.</p>
  <nav style={styles.footerNav}>
    {/* <a href="#" style={styles.linkStyle}>Terms of Service</a>
    <a href="#" style={styles.linkStyle}>Privacy</a> */}
  </nav>
</footer>
</>
  );
}
